import axios from "axios";
import { buildUrl } from "@/config";
import { isset } from "@/utils/functions";

export default {
  getPayrolls({ month, year, signed }) {
    let options = [];
    if (isset(month)) {
      options.push(`month=${month}`);
    }
    if (isset(year)) {
      options.push(`year=${year}`);
    }
    if (isset(signed)) {
      options.push(`signed=${signed}`);
    }

    return axios.get(buildUrl(`api/v1/payroll`, options));
  },

  getPdfPayroll({ month, year }) {
    return axios.get(buildUrl(`api/v1/payroll/get-pdf/${month}/${year}`));
  },

  downloadPdfPayroll({ month, year }) {
    return axios.get(buildUrl(`api/v1/payroll/download-pdf/${month}/${year}`), {
      responseType: "arraybuffer",
    });
  },

  sendSign({ sign, postedNumber }) {
    return axios.post(buildUrl(`api/v1/payroll/sign`), { sign, postedNumber });
  },

  getPayrollStatusEmployeeOfResponsible(year, { signed, employee }) {
    let params = new URLSearchParams([["year", year]]);
    if (isset(signed)) {
      params.append("signed", signed);
    }
    if (isset(employee)) {
      params.append("code", employee);
    }

    return axios.get(buildUrl(`api/v1/payroll/employees`), { params });
  },

  getPayrollsEmployeeOfResponsible(year, { signed, employee }) {
    let params = new URLSearchParams([["year", year]]);
    if (isset(signed)) {
      params.append("signed", signed);
    }
    if (isset(employee)) {
      params.append("code", employee);
    }

    return axios.get(buildUrl(`api/v1/payroll/employees/payrolls`), { params });
  },
  getCountPayrollsEmployeeOfResponsible(year, { signed, employee }) {
    let params = new URLSearchParams([["year", year]]);
    if (isset(signed)) {
      params.append("signed", signed);
    }
    if (isset(employee)) {
      params.append("code", employee);
    }

    return axios.get(buildUrl(`api/v1/payroll/employees/payrolls-responsible-count`), { params });
  },
};
