<template>
  <card-info-component
    :title="$t('pages.dashboard.widgets.payroll_for_sign')"
    :value="value"
    :is-loading="isLoading"
  ></card-info-component>
</template>

<script>
import { CardInfoComponent } from "@/pages/components";
import api from "@/api/payroll";

export default {
  components: {
    CardInfoComponent,
  },
  data() {
    return {
      value: 0,
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;

      api
        .getPayrolls({ signed: false })
        .then((response) => {
          this.value = response.data.length;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
